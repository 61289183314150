import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route /*Link*/,
} from "react-router-dom";
import "@patternfly/react-core/dist/styles/base.css";
import "./App.css";
import { NavSkiPage } from "./navigation";
import { Home } from "./home";
import { Humor } from "./humor";
import { SkiRedirect } from "./ski-redirect";
import { Golf } from "./plugin/golf";
import { Tennis } from "./plugin/tennis";
import { Info } from "./info";

const App: React.FC = () => {
  return (
    <Router>
      <NavSkiPage>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path="/tennis" element={<Tennis />} />
          <Route path="/golf" element={<Golf />} />
          <Route path="/humor" element={<Humor />} />
          <Route path="/help" element={<Info />} />
          <Route path="/info" element={<Info />} />
          <Route path="/ski2024" element={<SkiRedirect />} />
          <Route path="/ski2020" element={<SkiRedirect />} />
          <Route />
        </Routes>
        <div></div>
      </NavSkiPage>
    </Router>
  );
};

export default App;
