import React from "react";
import {
  AlertActionCloseButton,
  Alert,
  Button,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  TextInput,
} from "@patternfly/react-core";
import { /*AddCircleOIcon,*/ EditIcon } from "@patternfly/react-icons";
import { SchemaEditor } from "./schema-editor";

export const AddSchemaForm: React.FC<AddSchemaFormProps> = (props) => {
  const [activity, setActivity] = React.useState("");
  const [actSchema, setActSchema] = React.useState("");
  const [AAC, setAAC] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { userId, id, edit = false, statusMsg, item = null } = props;

  React.useEffect(() => {
    if (!item && id) {
      fetch(
        `${process.env.REACT_APP_ACT_API_SERVER}/actapi/?/schemasearch/id/${id}/user/${userId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setActivity(data?.[0]?.activity);
          setActSchema(data?.[0]?.activity_schema);
        })
        .catch(console.log);
    } else if (item) {
      setActivity(item?.activity);
      setActSchema(item?.activity_schema);
    }
  }, [id, item, userId]);

  const clearForm = () => {
    setActivity(item?.activity);
    setActSchema(item?.activity_schema);
    setAAC("");
  };

  const handleSchema = (v: string) => setActSchema(v);
  const handleActivity = (v: string, e: any) => setActivity(v);
  const handleAAC = (v: string, e: any) => setAAC(v);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    //Quick Validation:
    if (!userId || activity === "" || actSchema === "") {
      setMsg("You need to set all required fields[user, activity, and schema]");
      return;
    }
    const data = new FormData();

    if (id) {
      data.append("id", id.toString());
    }
    data.append("user", userId.toString());
    data.append("AAC", AAC);
    data.append("activity", activity);
    data.append("activity_schema", actSchema);

    const url = `${process.env.REACT_APP_ACT_API_SERVER}/actapi/?/schema`;
    const fetchMethod = "POST";
    fetch(url, {
      method: fetchMethod,
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setMsg(data);
        if (data.includes("Successful")) {
          handleModalToggle();
          if (typeof statusMsg !== "undefined") statusMsg(data);
        }
      })
      .catch(console.log);
  };

  const clearMsg = () => setMsg("");
  const handleModalToggle = () => setIsModalOpen(!isModalOpen);

  return (
    <div>
      {!edit && (
        <Button
          key="add-place-button"
          variant="primary"
          onClick={handleModalToggle}
        >
          Add Place
        </Button>
      )}
      {edit && (
        <EditIcon
          onClick={handleModalToggle}
          key={`edit-schema-${id}`}
        ></EditIcon>
      )}
      <Modal
        variant={ModalVariant.small}
        title={id ? "Edit Schema" : "Add Schema"}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button variant="primary" onClick={handleSubmit} key="submit-button">
            Submit
          </Button>,
          <Button variant="danger" onClick={clearForm} key="clear-button">
            Clear
          </Button>,
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
            Cancel
          </Button>,
        ]}
      >
        {msg && (
          <Alert
            variant="info"
            isInline
            title={msg}
            actionClose={<AlertActionCloseButton onClose={clearMsg} />}
          />
        )}
        <Form onSubmit={handleSubmit}>
          <FormGroup label="Activity" isRequired fieldId="activity-field">
            <TextInput
              value={activity}
              isRequired
              type="text"
              id="activity-field"
              aria-describedby="activity-field"
              name="activity-field"
              onChange={handleActivity}
            />
          </FormGroup>
          <FormGroup label="Schema" isRequired fieldId="schema-field">
            <div className="flex-display">
              <SchemaEditor edit schema={actSchema} onChange={handleSchema} />
              <TextInput
                value={actSchema}
                isReadOnly
                isRequired
                type="text"
                id="schema-field"
                aria-describedby="schema-field"
                name="schema-field"
                // onChange={handleSchema}
              />
            </div>
          </FormGroup>
          <FormGroup label="AAC" isRequired fieldId="add-place-aac">
            <TextInput
              value={AAC}
              isRequired
              type="password"
              id="place-aac"
              aria-describedby="place-aac"
              name="place-aac"
              onChange={handleAAC}
            />
          </FormGroup>
        </Form>
      </Modal>{" "}
    </div>
  );
};
export type AddSchemaFormProps = {
  item?: any;
  userId?: number;
  id?: number;
  statusMsg?: Function;
  edit?: boolean;
};
// const golfSchema = {
//   title: "Golf Log",
//   description: "Log for all my golfing",
//   fields: {
//     location: {
//       type: "string",
//       title: "Location",
//       required: true,
//     },
//     date: {
//       type: "date",
//       title: "Date",
//       required: true,
//     },
//     score: {
//       type: "number",
//       step: "1",
//       title: "Score",
//       required: true,
//     },
//     description: {
//       type: "string",
//       title: "Description",
//     },
//     cost: {
//       type: "number",
//       title: "Cost",
//       step: "01",
//     },
//     who: {
//       type: "string",
//       title: "Who you played with",
//     },
//   },
// };
// console.log("Output:", JSON.stringify(golfSchema));
