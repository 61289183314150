import React from "react";
import {
  //   Alert,
  Card,
  CardBody,
  CardExpandableContent,
  CardHeader,
  Gallery,
  GalleryItem,
  Text,
  TextContent,
  TextVariants,
} from "@patternfly/react-core";
// import { getStoredProp } from "./components/localStorage";

export const Info: React.FC<InfoProps> = (props) => {
  //   const [msg, setMsg] = React.useState("");
  //   const userId = getStoredProp("userId") || "1";
  const [isExpandedList, setIsExpandedList] = React.useState<string[]>([]);

  const handleCardExpand = (event: any, id: string) => {
    let list: string[] = [...isExpandedList];
    isExpandedList.includes(id)
      ? (list = isExpandedList.filter((v) => v !== id))
      : list.push(id);
    setIsExpandedList(list);
  };
  var body = document.getElementsByTagName("body")[0];
  body.setAttribute("class", "golf");

  return (
    <div id="humor">
      {/* {msg && <Alert title={msg} variant="info" timeout />} */}
      <TextContent className="indent">
        <Text component={TextVariants.h1}>Information</Text>
      </TextContent>

      <Gallery>
        <GalleryItem key="2022-Ski-Information-card">
          <Card
            id={"2022-Ski-Information"}
            isExpanded={isExpandedList.includes("2022-Ski-Information")}
          >
            <CardHeader onExpand={handleCardExpand}>
              <span className="humor_heading">2022 Ski Information</span>
            </CardHeader>
            <CardExpandableContent>
              <CardBody>
                <TextContent className="indent">
                  <Text component={TextVariants.h1}>Blackout Dates</Text>
                  <u>Ikon base:</u>
                  <br />• Dec. 26 - 31, 2022
                  <br />
                  • Jan. 14-15, 2023
                  <br />
                  • Feb. 18-19, 2023
                  <br />
                  <u>Snowbasin:</u>
                  <br />
                  • December 23- January 1<br />
                  • January 14-16
                  <br />
                  • February 18-20
                  <br />
                  <Text component={TextVariants.h1}>Parking</Text>
                  <u>Solitude:</u> $25/day free after 2pm Reservsations
                  <br />
                  <u>Alta:</u> Parking reservations are required Friday–Sunday &
                  holidays this winter, starting December 16th.{" "}
                  <a href="https://www.altaparking.com/">Parking Site</a>
                  <br />
                  <u>Snowbird:</u> Free parking, but you can reserve(pay) ahead
                  of time
                  <br />
                  <u>Brighton:</u> See reservations below
                  <Text component={TextVariants.h1}>Reservations</Text>
                  <u>Brighton:</u> Make your reservation through your{" "}
                  <a href="https://account.ikonpass.com/en/myaccount/add-reservations">
                    Ikon account
                  </a>
                  . Reservations are NOT required to ride past 2 p.m.
                  <br />
                  <u>Big Sky</u>{" "}
                  <a href="https://account.ikonpass.com/en/myaccount/add-reservations">
                    Ikon account
                  </a>
                  <br />
                  <u>Aspen Snowmass</u>{" "}
                  <a href="https://account.ikonpass.com/en/myaccount/add-reservations">
                    Ikon account
                  </a>
                  <br />
                  <u>Jackson Hole</u>{" "}
                  <a href="https://account.ikonpass.com/en/myaccount/add-reservations">
                    Ikon account
                  </a>
                  <br />
                  <u>Taos</u>{" "}
                  <a href="https://account.ikonpass.com/en/myaccount/add-reservations">
                    Ikon account
                  </a>
                  <br />
                  <u>Crystal Mountain WA</u> See their website
                  <br />
                </TextContent>
              </CardBody>
            </CardExpandableContent>
          </Card>
        </GalleryItem>
      </Gallery>
    </div>
  );
};

export type InfoProps = {};
