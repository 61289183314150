import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  TextInput,
} from "@patternfly/react-core";
import { /*AddCircleOIcon,*/ EditIcon } from "@patternfly/react-icons";
import { BuildSchemaComponent } from "./build-schema-component";

export const SchemaEditor: React.FC<SchemaEditorProps> = (props) => {
  const { edit = false, schema = "", onChange } = props;
  let schemaObj: any;
  if (schema) {
    try {
      schemaObj = JSON.parse(schema);
    } catch (e) {
      alert(e);
    }
  }
  const [title, setTitle] = React.useState(schemaObj?.title);
  const [description, setDescription] = React.useState(schemaObj?.description);
  const [inputValue, setInputValue] = React.useState<any>(schemaObj?.fields);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const getValueFromName = (_name: string): FieldEntry => {
    if (schemaObj?.fields?.hasOwnProperty(_name)) {
      return schemaObj.fields[_name];
    }
    return { label: "<unknown>", type: "string", required: false, step: 1 };
  };

  const handleInputs = (fieldName: string, obj: FieldEntry, remove = false) => {
    // console.log("Original Fields:", inputValue);
    let values = { ...inputValue };
    if (!remove) {
      values[fieldName] = obj;
      // console.log("Post Fields:", values);
      setInputValue(values);
    } else {
      // console.log("DELETE Original Fields:", inputValue);
      delete values[fieldName];
      setInputValue(values);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let newSchemaObject: SchemaObject = {
      title,
      description,
      fields: inputValue,
    };
    // console.log("New Schema Object", newSchemaObject);
    onChange(JSON.stringify(newSchemaObject));
    handleModalToggle();
  };

  const handleModalToggle = () => setIsModalOpen(!isModalOpen);

  const addField = () => {
    let values = { ...inputValue };
    values["New Field"] = {};
    setInputValue(values);
  };

  return (
    <div>
      {!edit && (
        <Button
          key="add-schema-button"
          variant="primary"
          onClick={handleModalToggle}
        >
          Add Schema
        </Button>
      )}
      {edit && (
        <EditIcon
          onClick={handleModalToggle}
          key="edit-schema"
          className="editicon-margin-top"
        />
      )}
      <Modal
        key="modal-edit-schema"
        variant={ModalVariant.small}
        title={edit ? "Edit Schema" : "Add Schema"}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button variant="primary" onClick={handleSubmit} key="submit-button">
            Save
          </Button>,
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
            Cancel
          </Button>,
        ]}
      >
        <Form isHorizontal onSubmit={handleSubmit}>
          <FormGroup
            key="schemaTitle"
            label="Title"
            isRequired
            fieldId="schema-title"
          >
            <TextInput
              key="schemaTitle"
              value={title}
              isRequired
              type="text"
              id="schema-title"
              aria-describedby="schema-title"
              name="schema-title"
              onChange={setTitle}
            />
          </FormGroup>
          <FormGroup
            key="schemaDesc"
            label="Description"
            isRequired
            fieldId="schema-desc"
          >
            <TextInput
              key="schemaDesc"
              value={description}
              isRequired
              type="text"
              id="schema-desc"
              aria-describedby="schema-desc"
              name="schema-desc"
              onChange={setDescription}
            />
          </FormGroup>
          {inputValue?.["New Field"] === undefined && (
            <Button isSmall key="Add-new-field-on-schema" onClick={addField}>
              Add Field
            </Button>
          )}

          {inputValue &&
            Object.keys(inputValue).map((fieldName: string, index) => {
              const field: FieldEntry = getValueFromName(fieldName);
              return (
                <BuildSchemaComponent
                  key={`${fieldName}-${index}`}
                  fieldName={fieldName}
                  fieldDescriptor={field}
                  onChange={handleInputs}
                />
              );
            })}
        </Form>
      </Modal>{" "}
    </div>
  );
};
export type SchemaEditorProps = {
  edit?: boolean;
  schema?: string;
  onChange: (json: string) => void;
};
export type FieldEntry = {
  label: string;
  title?: string;
  type: string;
  required: boolean;
  step?: number;
};
export type SchemaObject = {
  title: string;
  description: string;
  fields?: FieldEntry[];
};
