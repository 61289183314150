import React from "react";
import {
  AlertActionCloseButton,
  Alert,
  Button,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  TextInput,
} from "@patternfly/react-core";
import { /*AddCircleOIcon,*/ EditIcon } from "@patternfly/react-icons";
import { BuildFormComponent } from "./build-form-component";

export const AddActivityForm: React.FC<AddActivityFormProps> = (props) => {
  //   const [formData, setFormData] = React.useState();
  const [AAC, setAAC] = React.useState("");
  const [activityData, setActivityData] = React.useState("");
  const [lastUpdated, setLastUpdated] = React.useState("");
  const [inputValue, setInputValue] = React.useState<any>({});
  const [msg, setMsg] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {
    userId = "",
    id = "",
    edit = false,
    statusMsg,
    item = null,
    actType,
    schema = "",
  } = props;
  let schemaObj: any;
  if (schema) {
    try {
      schemaObj = JSON.parse(schema);
    } catch (e) {
      alert(e);
    }
  }
  React.useEffect(() => {
    if (!item && id !== "") {
      fetch(
        `${process.env.REACT_APP_ACT_API_SERVER}/actapi/?/actsearch/id/${id}/user/${userId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setActivityData(data?.[0]?.data);
          setLastUpdated(data?.[0]?.last_updated);
        })
        .catch(console.log);
    } else if (item) {
      setActivityData(item?.data);
      setLastUpdated(item?.last_updated);
    }
  }, [id, item, userId]);

  const getValueFromName = (_name: string) => {
    if (inputValue.hasOwnProperty(_name)) {
      return inputValue[_name];
    }
    return "";
  };

  const handleInputs = (v: string, e: any) => {
    let values = { ...inputValue };
    values[e.target.name] = v;
    setInputValue(values);
  };

  const clearForm = () => {
    setActivityData("");
    setAAC("");
  };

  const handleAAC = (v: string, e: any) => setAAC(v);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (userId === "" || actType === "") {
      setMsg("You need to set all required fields[user, activity, and data]");
      return;
    }

    const data = new FormData();
    data.append("id", id as string);
    data.append("user", userId.toString());
    data.append("AAC", AAC);
    data.append("data", JSON.stringify(inputValue));
    data.append("activity", actType);

    const url = `${process.env.REACT_APP_ACT_API_SERVER}/actapi/?/act`;
    const fetchMethod = "POST";
    fetch(url, {
      method: fetchMethod,
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setMsg(data);
        if (data.includes("Successful")) {
          handleModalToggle();
          if (typeof statusMsg !== "undefined") statusMsg(data);
        }
      })
      .catch(console.log);
  };

  const clearMsg = () => setMsg("");
  const handleModalToggle = () => setIsModalOpen(!isModalOpen);

  return (
    <div>
      {!edit && (
        <Button
          key="add-place-button"
          variant="primary"
          onClick={handleModalToggle}
        >
          Add Activity
        </Button>
      )}
      {edit && (
        <EditIcon
          onClick={handleModalToggle}
          key={`edit-schema-${id}`}
        ></EditIcon>
      )}
      <Modal
        variant={ModalVariant.small}
        title={id === "" ? "Add Activity" : "Edit Activity"}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button variant="primary" onClick={handleSubmit} key="submit-button">
            Submit
          </Button>,
          <Button variant="danger" onClick={clearForm} key="clear-button">
            Clear
          </Button>,
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
            Cancel
          </Button>,
        ]}
      >
        {msg && (
          <Alert
            variant="info"
            isInline
            title={msg}
            actionClose={<AlertActionCloseButton onClose={clearMsg} />}
          />
        )}

        <Form isHorizontal onSubmit={handleSubmit}>
          {schemaObj?.fields &&
            Object.keys(schemaObj?.fields).map((key) => {
              return (
                <BuildFormComponent
                  key={`build-activity-pieces-${key}`}
                  value={getValueFromName(key)}
                  onChange={handleInputs}
                  componentType={schemaObj?.fields[key]?.type}
                  name={key}
                  title={
                    schemaObj?.fields[key]?.label ||
                    schemaObj?.fields[key]?.title
                  }
                  required={schemaObj?.fields[key]?.required}
                  step={schemaObj?.fields[key]?.step}
                />
              );
            })}

          <FormGroup label="AAC" isRequired fieldId="add-place-aac">
            <TextInput
              value={AAC}
              isRequired
              type="password"
              id="place-aac"
              aria-describedby="place-aac"
              name="place-aac"
              onChange={handleAAC}
            />
          </FormGroup>
        </Form>
        {lastUpdated && <span>Updated at {lastUpdated}</span>}
        {activityData && <span>Data: {activityData}</span>}
      </Modal>{" "}
    </div>
  );
};
export type AddActivityFormProps = {
  item?: any;
  userId?: number;
  id?: number;
  statusMsg?: Function;
  edit?: boolean;
  actType: string;
  schema?: string;
};
