import React from "react";
import { FormGroup, TextInput } from "@patternfly/react-core";

export const BuildFormComponent: React.FC<BuildFormProps> = (props) => {
  const {
    value, // at this point, this is an array of KeyValue pairs
    onChange,
    componentType = "string",
    name = "",
    title = "",
    required = false,
    step = 1,
  } = props;

  switch (componentType) {
    case "number":
      return (
        <FormGroup
          label={title}
          isRequired={required}
          fieldId={`${name}`}
          key={name}
        >
          <TextInput
            value={value}
            isRequired={required}
            type="number"
            step={step}
            id={`${name}`}
            aria-describedby={`${name}`}
            name={`${name}`}
            onChange={onChange}
            key={name}
          />
        </FormGroup>
      );
    case "date":
      return (
        <FormGroup
          key={name}
          label={title}
          isRequired={required}
          fieldId={`${name}`}
        >
          <TextInput
            value={value}
            isRequired={required}
            type="date"
            id={`${name}`}
            aria-describedby={`${name}`}
            name={`${name}`}
            onChange={onChange}
            key={name}
          />
        </FormGroup>
      );

    case "string":
    default:
      return (
        <FormGroup
          key={name}
          label={title}
          isRequired={required}
          fieldId={`${name}`}
        >
          <TextInput
            value={value}
            isRequired={required}
            type="text"
            id={`${name}`}
            aria-describedby={`${name}`}
            name={`${name}`}
            onChange={onChange}
            key={name}
          />
        </FormGroup>
      );
  }
};
export type BuildFormProps = {
  value: any;
  onChange: any;
  componentType: string;
  name: string;
  title: string;
  required?: boolean;
  step?: number;
};
