import React from "react";
import {
  //   Alert,
  Card,
  CardBody,
  CardExpandableContent,
  CardHeader,
  Gallery,
  GalleryItem,
  Text,
  TextContent,
  TextVariants,
} from "@patternfly/react-core";
// import { getStoredProp } from "./components/localStorage";

export const Humor: React.FC<HumorProps> = (props) => {
  //   const [msg, setMsg] = React.useState("");
  //   const userId = getStoredProp("userId") || "1";
  const [isExpandedList, setIsExpandedList] = React.useState<string[]>([]);

  const handleCardExpand = (event: any, id: string) => {
    let list: string[] = [...isExpandedList];
    isExpandedList.includes(id)
      ? (list = isExpandedList.filter((v) => v !== id))
      : list.push(id);
    setIsExpandedList(list);
  };
  var body = document.getElementsByTagName("body")[0];
  body.setAttribute("class", "golf");

  return (
    <div id="humor">
      {/* {msg && <Alert title={msg} variant="info" timeout />} */}
      <TextContent className="indent">
        <Text component={TextVariants.h1}>Fun Stuff</Text>
      </TextContent>

      <Gallery>
        <GalleryItem key="humor-card-addicition">
          <Card
            id={"humor-skiaddiction"}
            isExpanded={isExpandedList.includes("humor-skiaddiction")}
          >
            <CardHeader onExpand={handleCardExpand}>
              <span className="humor_heading">
                You Know You are Addicted to Skiing when...
              </span>
            </CardHeader>
            <CardExpandableContent>
              <CardBody>
                <div className="indent">
                  <ol>
                    <li>You get married at a ski resort (who doesn't???).</li>
                    <li>You marry a ski instructor.</li>
                    <li>
                      Winter Break/Xmas/Chanuakah is just an excuse to ski.
                    </li>
                    <li>Your dog skis.</li>
                    <li>You have more than 3 pairs of skis (who doesn't?)</li>
                    <li>
                      You ski Corbet's... all the time (Jackson Hole reference)
                    </li>
                    <li>
                      All your personal property is covered with skiing
                      stickers.
                    </li>
                    <li>To you, there is no such thing as a "bad" ski day.</li>
                    <li>You break at least one pair of skis a season.</li>
                    <li>Climbing to the top is a choice, not a neccessity.</li>
                    <li>
                      You ski more at 27 than you did at 17 (again, who
                      doesn't?)
                    </li>
                    <li>Any powder is good powder.</li>
                    <li>
                      To you, the ski season is 7 months long and getting longer
                      every year.
                    </li>
                    <li>
                      You judge all vehicles by how well they will carry your
                      skis.
                    </li>
                    <li>
                      To you, I-70 traffic is enjoyable and relaxing (you might
                      have a problem).
                    </li>
                    <li>
                      Your office walls are covered with ski maps and ski
                      posters (whose aren't?)
                    </li>
                    <li>
                      You spend the whole day in the park and love every single
                      minute of it.
                    </li>
                    <li>You hike more than you ride chairlifts.</li>
                    <li>You have more than 3 season passes in one season.</li>
                    <li>
                      You are seriously thinking of taking 3 months off from
                      work during the upcoming season.
                    </li>
                    <li>
                      Every place you live is judged by accessiblilty to the ski
                      country.
                    </li>
                    <li>You wake up before 6am to get first tracks.</li>
                    <li>
                      "Skiing is life and everything else is just details."
                    </li>
                    <li>
                      You have more skiing appearal than business clothing
                      (Again, who doesn't?).
                    </li>
                    <li>Your poles stay in your car from October thru May.</li>
                    <li>You have named the snow gods.</li>
                    <li>You have a shrine dedicated to the snow gods.</li>
                    <li>
                      You curse the snow gods on cloudy, cold, non-snowy days.
                    </li>
                    <li>
                      You give sacrifices to the snow gods on knee-deep powder,
                      blue-sky sunshine days.
                    </li>
                    <li>
                      You have web pages dedicated to skiing (wait, one sec...).
                    </li>
                    <li>You really, really like to ski.</li>
                    <li>
                      You always carry your skis on/in your car "just in case."
                    </li>
                    <li>
                      When someone asks you how long it take to get somwhere,
                      you base the time from your favorite ski area.
                    </li>
                    <li>
                      You're constantly working on a plan to take off for a ski
                      area, make a few runs, and then get back to class/work
                      before lunch is over.
                    </li>
                    <li>
                      You've replaced your driver's licsense with your season
                      pass.
                    </li>
                    <li>
                      You named your children Mary and Jane or Warren and
                      Miller.
                    </li>
                    <li>
                      If it has been more than a week since your last ski trip,
                      you start going through withdrawal.
                    </li>
                    <li>
                      Your answering machine goes something like this, "Good
                      morning skiers, it's another great day to ski ..."
                    </li>
                    <li>
                      You're always grumpy starting in May thru the end of
                      October.
                    </li>
                    <li>
                      You wax your skis more than your car (Who Doesn't??)
                    </li>
                    <li>
                      Whenever you get near a ski area, you start drouling and
                      yelling short rhyming phrases such as "I'm starving for
                      the carving!" and "I have a need to ski!"
                    </li>
                    <li>
                      You like running through large crowds of people to "test
                      your line" (moguls reference).
                    </li>
                    <li>Your goal in college: To ski across campus.</li>
                    <li>
                      Everytime it snows, your eyes water, and you try on your
                      skis.
                    </li>
                    <li>
                      The two most beautiful sylables in the English language:
                      Pow-der.
                    </li>
                    <li>Your hero in life: Ski Bum.</li>
                    <li>Your ideal woman: Ski Bunny.</li>
                    <li>
                      Your ideal piece of living room furniture: A lift chair.
                    </li>
                    <li>
                      In your electronics classes, you ask your prof daily to
                      explain how the K2-four's anti-vibration system works.
                    </li>
                    <li>Duct tape makes any ski equipment like new.</li>
                    <li>
                      After trying a 360 off a 20 foot drop, braking your $60
                      goggles, losing a $300 eyeglass lense, and cutting a gash
                      in your forhead, you get up and try the jump again.
                    </li>
                    <li>
                      To you, there are only two challenges in life: moguls and
                      trees.{" "}
                    </li>
                  </ol>
                </div>
              </CardBody>
            </CardExpandableContent>
          </Card>
        </GalleryItem>
        <GalleryItem key="humor-card-chili">
          <Card
            id={"humor-chili"}
            isExpanded={isExpandedList.includes("humor-chili")}
          >
            <CardHeader onExpand={handleCardExpand}>
              <span className="humor_heading">Chili Cook-off</span>
            </CardHeader>
            <CardExpandableContent>
              <CardBody>
                <div className="indent">
                  <TextContent>
                    <Text component={TextVariants.p}>
                      NOTE: Please take time to read this slowly. If you pay
                      attention to the first two judges, the reaction of the
                      third is even better. For those of you who may have lived
                      in Texas, you know how true this is! They actually have a
                      chili cook-off about the time the rodeo comes to town. It
                      takes up a major portion of the parking lot at the
                      Astrodome! You will likely want to read this behind closed
                      doors because, if you are like me, you will be howling out
                      loud.
                    </Text>
                    <Text component={TextVariants.p}>
                      INEXPERIENCED CHILI TASTER <br />
                      Notes From An Inexperienced Chili Tester Named FRANK, who
                      was visiting Texas from the East Coast: "Recently, I was
                      honored to be selected as a judge at a chili cook-off. The
                      original person called in sick at the last moment and I
                      happened to be standing there at the judge's table asking
                      directions to the beer wagon, when the call came. I was
                      assured by the other two judges (Native Texans) that the
                      chili wouldn't be all that spicy, and besides, they told
                      me I could have free beer during the tasting, So I
                      accepted." <br />
                      Here are the scorecards from the event:
                    </Text>
                    <Text component={TextVariants.p}>
                      __________________________________________________________
                      <br />
                      CHILI # 1 MIKE'S MANIAC MOBSTER MONSTER CHILI <br />
                      JUDGE ONE: A little too heavy on tomato. Amusing kick.{" "}
                      <br />
                      JUDGE TWO: Nice, smooth tomato flavor. Very mild. <br />
                      FRANK: Holy shit, what the hell is this stuff? You could
                      remove dried paint from your driveway. Took me two beers
                      to put the flames out. I hope that's the worst one. These
                      Texans are crazy.
                    </Text>
                    <Text component={TextVariants.p}>
                      _________________________________________________________
                      <br />
                      CHILI # 2 ARTHUR'S AFTERBURNER CHILI <br />
                      JUDGE ONE: Smokey, with a hint of pork. Slight Jalapeno
                      tang. <br />
                      JUDGE TWO: Exciting BBQ flavor, needs more peppers to be
                      taken seriously. <br />
                      FRANK: Keep this out of the reach of children I'm not sure
                      what I am supposed to taste besides pain. I had to wave
                      off two people who wanted to give me the Heimlich
                      maneuver. They had to rush in more beer when they saw the
                      look on my face.
                    </Text>
                    <Text component={TextVariants.p}>
                      __________________________________________________________
                      <br />
                      CHILI # 3 FRED'S FAMOUS BURN DOWN THE BARN CHILI <br />
                      JUDGE ONE: Excellent firehouse chili! Great kick. Needs
                      more beans. <br />
                      JUDGE TWO: A beanless chili, a bit salty, good use of
                      peppers. <br />
                      FRANK: Call the EPA, I've located a uranium spill. My nose
                      feels like I have been snorting Drano. Everyone knows the
                      routine by now get me more beer before I ignite. Barmaid
                      pounded me on the back; now my backbone is in the front
                      part of my chest. I'm getting shit-faced from all the
                      beer.
                    </Text>
                    <Text component={TextVariants.p}>
                      ________________________________________________________
                      <br />
                      CHILI # 4 BUBBA'S BLACK MAGIC <br />
                      JUDGE ONE: Black bean chili with almost no spice.
                      Disappointing. <br />
                      JUDGE TWO: Hint of lime in the black beans. Good side dish
                      for fish or other mild foods, not much of a chili. <br />
                      FRANK: I felt something scraping across my tongue, but was
                      unable to taste it, is it possible to burnout taste buds?
                      Sally, the barmaid, was standing behind me with fresh
                      refills; that 300 lb. bitch is starting to look HOT just
                      like this nuclear waste I'm eating. Is chili an
                      aphrodisiac?
                    </Text>
                    <Text component={TextVariants.p}>
                      _______________________________________________________
                      <br />
                      CHILI # 5 LINDA'S LEGAL LIP REMOVER <br />
                      JUDGE ONE: Meaty, strong chili. Cayenne peppers freshly
                      ground,adding considerable kick. Very Impressive. <br />
                      JUDGE TWO: Chili using shredded beef, could use more
                      tomato. Must admit the cayenne peppers make a strong
                      statement. <br />
                      FRANK: My ears are ringing, sweat is pouring off my
                      forehead and I can no longer focus my eyes. I farted and
                      four people behind me needed paramedics. The contestant
                      seemed offended when I told her that her chili had given
                      me brain damage, Sally saved my tongue from bleeding by
                      pouring beer directly on it from a pitcher. I wonder if
                      I'm burning my lips off? It really pisses me off that the
                      other judges asked me to stop screaming. Screw those
                      rednecks!
                    </Text>
                    <Text component={TextVariants.p}>
                      ________________________________________________________
                      <br />
                      CHILI # 6 VERA'S VERY VEGETARIAN VARIETY <br />
                      JUDGE ONE: Thin yet bold vegetarian variety chili. Good
                      balance of spice and peppers. <br />
                      JUDGE TWO: The best yet. Aggressive use of peppers,
                      onions, and garlic. Superb. <br />
                      FRANK: My intestines are now a straight pipe filled with
                      gaseous, sulfuric flames. I shit myself when I farted and
                      I'm worried it will eat through the chair. No one seems
                      inclined to stand behind me except Sally. Can't feel my
                      lips anymore. I need to wipe my ass with a snow cone!
                    </Text>
                    <Text component={TextVariants.p}>
                      ___________________________________________________
                      <br /> CHILI # 7 SUSAN'S SCREAMING SENSATION CHILI <br />
                      JUDGE ONE: A mediocre chili with too much reliance on
                      canned peppers.
                      <br />
                      JUDGE TWO: Ho Hum, tastes as if the chef literally threw
                      in a can of chili peppers at the last moment. I should
                      take note that I am worried about Judge Number 3, He
                      appears to be in a bit of distress as he is cursing
                      uncontrollably. <br />
                      FRANK: You could put a grenade in my mouth, pull the pin,
                      and I wouldn't feel a damn thing. I've lost sight in one
                      eye, and the world sounds like it is made of rushing
                      water. My shirt is covered with chili which slid unnoticed
                      out of my mouth. My pants are full of lava-like shit to
                      match my damn shirt. At least during the autopsy they'll
                      know what killed me .I've decided to stop breathing, it's
                      too painful. Screw it, I'm not getting any oxygen anyway.
                      If I need air, I'll just suck it in through the 4-inch
                      hole in my stomach.
                    </Text>
                  </TextContent>
                </div>
              </CardBody>
            </CardExpandableContent>
          </Card>
        </GalleryItem>
        <GalleryItem key="humor-card-3">
          <Card
            id={"humor-drunken-fools"}
            isExpanded={isExpandedList.includes("humor-drunken-fools")}
          >
            <CardHeader onExpand={handleCardExpand}>
              <span className="humor_heading">Drunken Fools</span>
            </CardHeader>
            <CardExpandableContent>
              <CardBody>
                <div className="indent">
                  <TextContent>
                    <Text component={TextVariants.p}>
                      Two men are drinking in a bar at the top of the Empire
                      State Building. One turns to the other and says: "You know
                      last week I discovered that if you jump from the top of
                      this building- by the time you fall to the 10th floor, the
                      winds around the building are so intense that they carry
                      you around the building and back into the window." The
                      bartender just shakes his head in disapproval while wiping
                      the bar.
                    </Text>
                    <Text component={TextVariants.p}>
                      The 2nd Man says: "What are you a nut? There is no way in
                      heck that could happen."
                      <br />
                      1st Man: "No it's true let me prove it to you." So he gets
                      up from the bar, jumps over the balcony, and careens to
                      the street below. When he passes the 10th floor, the high
                      wind whips him around the building and back into the 10th
                      floor window and he takes the elevator back up to the bar.
                      <br />
                      The 2nd Man tells him: "You know I saw that with my own
                      eyes, but that must have been a one time fluke."
                      <br />
                      1st Man: "No, I'll prove it again" and again he jumps and
                      hurtles toward the street where the 10th floor wind gently
                      carries him around the building and into the window. Once
                      upstairs he urges his fellow drinker to try it.
                      <br />
                      2nd Man: "Well what the heck, it works, I'll try it." So
                      he jumps over the balcony, plunges downward, passes the
                      11th, 10th, 9th, 8th floors and hits the sidewalk with a
                      'splat.'
                      <br />
                      Back upstairs the Bartender turns to the other drinker:
                      <br />
                      "You know, Superman, you're a real jerk when you're
                      drunk."
                      <br />
                    </Text>
                  </TextContent>
                </div>
              </CardBody>
            </CardExpandableContent>
          </Card>
        </GalleryItem>
        <GalleryItem key="humor-card-4">
          <Card
            id={"humor-politics"}
            isExpanded={isExpandedList.includes("humor-politics")}
          >
            <CardHeader onExpand={handleCardExpand}>
              <span className="humor_heading">Politics</span>
            </CardHeader>
            <CardExpandableContent>
              <CardBody>
                <div className="indent">
                  <TextContent>
                    <Text component={TextVariants.p}>
                      <b>Son:</b> "Daddy, I have to write a special report for
                      school, but I don't know what Politics is."
                      <br />
                      <br />
                      <b>Father:</b> "Well, let's take our home as an example. I
                      am the bread-winner, so let's call me 'Capitalism.' Your
                      Mum is the administrator of money, so we'll call her
                      'Government.' We take care of your needs, so let's call
                      you 'The People.' We'll call the maid 'The Working Class'
                      and your brother we can call 'The Future.' Do you
                      understand son?"
                      <br />
                      <br />
                      <b>Son:</b> "I'm not really sure, Dad. I'll have to think
                      about it."
                      <br />
                      <br />
                      That night awakened by his brother's crying, the boy went
                      to see what was wrong. Discovering that the baby had
                      seriously soiled his diaper, the boy went to his parents'
                      room and found his mother sound asleep. He went to the
                      maid's room, where, peeking through the keyhole, he saw
                      his father in bed with the maid. The boy's knocking went
                      totally unheeded by his father and the maid, so the boy
                      returned to his room and went back to sleep.
                      <br />
                      <br />
                      The next morning he reported to his father.
                      <br />
                      <br />
                      <b>Son:</b> "Dad, now I think I understand what Politics
                      is."
                      <br />
                      <br />
                      <b>Father:</b> "Good son! Can you explain it to me in your
                      own words?"
                      <br />
                      <br />
                      <b>Son:</b> "Well Dad, while Capitalism is screwing the
                      Working Class, Government is sound asleep, the People are
                      being completely ignored and the Future is full of Shit."
                      <br />
                    </Text>
                  </TextContent>
                </div>
              </CardBody>
            </CardExpandableContent>
          </Card>
        </GalleryItem>
        <GalleryItem key="humor-card-5">
          <Card
            id={"humor-taking"}
            isExpanded={isExpandedList.includes("humor-taking")}
          >
            <CardHeader onExpand={handleCardExpand}>
              <span className="humor_heading">Taking it with you</span>
            </CardHeader>
            <CardExpandableContent>
              <CardBody>
                <div className="indent">
                  <TextContent>
                    <Text component={TextVariants.p}>
                      An old man was on his death bed, and wanted to be buried
                      with his money. He called his priest, his doctor and his
                      lawyer to his bedside. "Here's $30,000 cash to be held by
                      each of you. I trust you to put this in my coffin when I
                      die so I can take all my money with me."
                      <br />
                      <br />
                      At the funeral, each man put an envelope in the coffin.
                      Riding away in a limousine, the priest suddenly broke into
                      tears and confessed, "I only put $20,000 into the envelope
                      because I needed $10,000 to repair the roof of the
                      church."
                      <br />
                      <br />
                      "Well, since we're confiding in each other," said the
                      doctor, "I only put $10,000 in the envelope because we
                      needed a new X-ray machine for the pediatrics ward at the
                      hospital which cost $20,000."
                      <br />
                      <br />
                      The lawyer was aghast. "I'm ashamed of both of you," he
                      exclaimed. "I want it known that when I put my envelope in
                      that coffin, I enclosed a check for the full $30,000."
                      <br />
                    </Text>
                  </TextContent>
                </div>
              </CardBody>
            </CardExpandableContent>
          </Card>
        </GalleryItem>
      </Gallery>
    </div>
  );
};

export type HumorProps = {};
