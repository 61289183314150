import React from "react";
import { Table, TableHeader, TableBody } from "@patternfly/react-table";
import { ActivityLog } from "../plugin/golf";

export const BuildTableFromJSONComponent: React.FC<BuildTableFromJSONProps> = (
  props
) => {
  //   const [activities, setActivities] = React.useState<any[]>([]);
  const [columns, setColumns] = React.useState<string[]>([]);
  const [tableRows, setTableRows] = React.useState<string[][]>([[]]);
  const { items = [], schema = "" } = props;

  React.useEffect(() => {
    let schemaObj: any;
    if (schema) {
      try {
        schemaObj = JSON.parse(schema);
      } catch (e) {
        alert(e);
      }
    }
    var dataObj: any[] = [];

    const getObjFromJSON = (data: any) => {
      try {
        return JSON.parse(data);
      } catch (e) {
        alert(e);
      }
    };
    items.forEach((item) => {
      dataObj[item?.id] = getObjFromJSON(item?.data);
    });
    // setActivities(dataObj);
    var cols: string[] = [];
    var colLabels: string[] = [];
    var rows: string[][] = [];
    dataObj.forEach((activity) => cols.push(...Object.keys(activity)));
    // We have the list of fields, now lets cross reference with schema for Label
    var uniq = Array.from(new Set<string>(cols));
    uniq.forEach((field) =>
      colLabels.push(
        schemaObj?.fields[field]?.label || schemaObj?.fields[field]?.title
      )
    );

    setColumns(colLabels);
    // Set the row values now
    dataObj.forEach((activity) => {
      let row: string[] = [];
      for (var i = 0; i < uniq.length; i++) {
        row.push(activity[uniq[i]]);
      }
      rows.push(row);
    });
    setTableRows(rows);
  }, [items, schema]);

  return (
    <Table
      aria-label="Simple Table"
      variant="compact"
      cells={columns}
      rows={tableRows}
    >
      <TableHeader />
      <TableBody />
    </Table>
  );
};
export type BuildTableFromJSONProps = {
  items?: ActivityLog[];
  schema?: string;
};
