import React from "react";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FlexItem,
  Gallery,
  GalleryItem,
  Spinner,
} from "@patternfly/react-core";
import { getStoredProp } from "../components/localStorage";
import { AddSchemaForm } from "../components/add-schema";
import { AddActivityForm } from "../components/add-activity";
import { BuildTableFromJSONComponent } from "../components/build-table-from-json";

export const Tennis: React.FC<TennisProps> = (props) => {
  const [items, setItems] = React.useState<ActivityLog[]>();
  const [schema, setSchema] = React.useState<SchemaLog[]>();
  const [msg, setMsg] = React.useState("");
  const userId = getStoredProp("userId") || "1";

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_ACT_API_SERVER}/actapi/?/actsearch/activity/tennis/user/${userId}`
    )
      .then((res) => res.json())
      .then((data) => {
        let logData: ActivityLog[] = [];
        for (var i = 0; i < data.length; i++) {
          var obj: ActivityLog = data[i];
          logData.push(obj);
        }
        setItems(logData);
        setMsg(`Loaded ${data.length} activities`);
      })
      .catch(console.log);
  }, [userId]);

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_ACT_API_SERVER}/actapi/?/schemasearch/activity/tennis/user/${userId}/status/enabled`
    )
      .then((res) => res.json())
      .then((data) => {
        let logData: SchemaLog[] = [];
        for (var i = 0; i < data.length; i++) {
          var obj: SchemaLog = data[i];
          logData.push(obj);
        }
        setSchema(logData);
      })
      .catch(console.log);
  }, [userId]);
  var body = document.getElementsByTagName("body")[0];
  body.setAttribute("class", "golf");

  return (
    <div id="main">
      {msg && <Alert title={msg} variant="info" timeout />}
      <Gallery>
        <GalleryItem key="activities-card">
          <Card>
            <CardHeader>
              <Flex>
                <FlexItem className="card-header_color">Tennis Log</FlexItem>
                <FlexItem align={{ default: "alignRight" }}>
                  <AddActivityForm
                    actType="tennis"
                    userId={userId}
                    schema={schema?.[0]?.activity_schema}
                    statusMsg={setMsg}
                  />
                </FlexItem>
                {schema?.map((item, i) => (
                  <FlexItem
                    key={`${item.activity}-${item.last_updated}`}
                    align={{ default: "alignRight" }}
                    className="card-header_color"
                  >
                    <AddSchemaForm
                      userId={item.user}
                      id={item.id}
                      item={item}
                      edit
                    />
                  </FlexItem>
                ))}
                )
              </Flex>
            </CardHeader>
            <CardBody>
              {" "}
              {items &&
                schema &&
                items?.length !== 0 &&
                schema?.[0]?.activity_schema !== "" && (
                  <BuildTableFromJSONComponent
                    key="table-for-activities"
                    items={items}
                    schema={schema?.[0]?.activity_schema}
                  />
                )}
              {items?.length === 0 && <Spinner>Loading items....</Spinner>}
              {schema?.[0]?.activity_schema === "" && (
                <Spinner>Loading schema...</Spinner>
              )}
            </CardBody>
          </Card>
        </GalleryItem>
      </Gallery>
    </div>
  );
};

export type TennisProps = {};

export type GolfLog = {
  date: string;
  location: string;
  desc: string;
  score: number;
  cost: number;
  who: string;
};

export type ActivityLog = {
  id: number;
  user: number;
  activity: string;
  last_updated: string;
  data: string;
};

export type SchemaLog = {
  id: number;
  user: number;
  activity: string;
  last_updated: string;
  activity_schema: string;
};
