import React from "react";
// import brandImg from "./src-images/logo.png";
import {
  //   Button,
  Modal,
  ModalVariant,
  LoginForm,
  //   LoginPage,
  //   BackgroundImageSrc
} from "@patternfly/react-core";
import { ExclamationCircleIcon } from "@patternfly/react-icons";

export const Login: React.FC<LoginProps> = (props) => {
  const [showHelperText, setShowHelperText] = React.useState(false);
  const [usernameValue, setUsernameValue] = React.useState("");
  const [isValidUsername, setIsValidUsername] = React.useState(true);
  const [passwordValue, setPasswordValue] = React.useState("");
  const [isValidPassword, setIsValidPassword] = React.useState(true);
  const [isRememberMeChecked, setIsRememberMeChecked] = React.useState(true);

  const handleUsernameChange = (value: string) => {
    setUsernameValue(value);
  };

  const handlePasswordChange = (password: string) => {
    setPasswordValue(password);
  };

  const onRememberMeClick = () => {
    setIsRememberMeChecked(!isRememberMeChecked);
  };
  const { isLoginOpen, handleLoginToggle, onChange } = props;

  const onLoginButtonClick = (event: any) => {
    event.preventDefault();
    setIsValidUsername(!!usernameValue);
    setIsValidPassword(!!passwordValue);
    setShowHelperText(!usernameValue || !passwordValue);
    onChange(usernameValue);
  };

  const helperText = (
    <React.Fragment>
      <ExclamationCircleIcon />
      &nbsp;Invalid login credentials.
    </React.Fragment>
  );

  const loginForm = (
    <LoginForm
      showHelperText={showHelperText}
      helperText={helperText}
      usernameLabel="Username"
      usernameValue={usernameValue}
      onChangeUsername={handleUsernameChange}
      isValidUsername={isValidUsername}
      passwordLabel="Password"
      passwordValue={passwordValue}
      onChangePassword={handlePasswordChange}
      isValidPassword={isValidPassword}
      rememberMeLabel="Keep me logged in for 30 days."
      isRememberMeChecked={isRememberMeChecked}
      onChangeRememberMe={onRememberMeClick}
      onLoginButtonClick={onLoginButtonClick}
    />
  );

  return (
    <>
      <Modal
        variant={ModalVariant.small}
        title={"Login"}
        isOpen={isLoginOpen}
        onClose={handleLoginToggle}
      >
        {loginForm}
      </Modal>
    </>
  );
};

export type LoginProps = {
  onChange: Function;
  isLoginOpen: boolean;
  handleLoginToggle: () => void;
};
