import React from "react";
import {
  AboutModal,
  Button,
  ButtonVariant,
  TextContent,
  TextList,
  TextListItem,
} from "@patternfly/react-core";
import { CogIcon } from "@patternfly/react-icons";
import brandImg from "../src-images/logo.png";

export const AboutLucky13s: React.FC<AboutModalProps> = (props) => {
  const version = "2.1.20200404";
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { showCog = true, showOpen = false } = props;
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  React.useEffect(() => {
    if (showOpen) {
      setIsModalOpen(true);
    }
  }, [showOpen]);
  return (
    <React.Fragment>
      {showCog && !showOpen && (
        <Button
          id="about-lucky13s-button-cog"
          aria-label="Settings actions"
          variant={ButtonVariant.plain}
          onClick={handleModalToggle}
        >
          <CogIcon />
        </Button>
      )}
      {!showCog && !showOpen && (
        <Button
          variant="primary"
          onClick={handleModalToggle}
          key="about-lucky13s-button"
        >
          <CogIcon />
          About
        </Button>
      )}
      <AboutModal
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        trademark={`copyright 1995 - ${new Date().getFullYear()}`}
        brandImageSrc={brandImg}
        brandImageAlt="Lucky13s"
        productName="Lucky13s"
      >
        <TextContent>
          <TextList component="dl">
            <TextListItem component="dt">Code Version</TextListItem>
            <TextListItem component="dd">{version}</TextListItem>
            <TextListItem component="dt">User Name</TextListItem>
            <TextListItem component="dd">guest</TextListItem>
            <TextListItem component="dt">User Role</TextListItem>
            <TextListItem component="dd">reader</TextListItem>
            <TextListItem component="dt">Browser Version</TextListItem>
            <TextListItem component="dd">{navigator.userAgent}</TextListItem>
            <TextListItem component="dt">Browser View Dimensions</TextListItem>
            <TextListItem component="dd">
              {document.documentElement.clientWidth} x{" "}
              {document.documentElement.clientHeight}
            </TextListItem>
            <TextListItem component="dt">
              Stick Figure Icon credits
            </TextListItem>
            <TextListItem component="dd">
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/freepik"
                title="Freepik"
              >
                Freepik
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                {" "}
                www.flaticon.com
              </a>
            </TextListItem>
          </TextList>
        </TextContent>
      </AboutModal>
    </React.Fragment>
  );
};
export type AboutModalProps = {
  showCog?: boolean;
  showOpen?: boolean;
};
