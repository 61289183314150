import React from "react";
import { Button, Checkbox, FormGroup, TextInput } from "@patternfly/react-core";
import { FieldEntry } from "./schema-editor";

export const BuildSchemaComponent: React.FC<BuildSchemaProps> = (props) => {
  const { fieldName, fieldDescriptor, onChange } = props;
  const [name, setName] = React.useState(fieldName);
  const [type, setType] = React.useState(fieldDescriptor?.type);
  const [label, setLabel] = React.useState(
    fieldDescriptor?.label || fieldDescriptor?.title
  );
  const [step, setStep] = React.useState(fieldDescriptor?.step);
  const [required, setRequired] = React.useState(fieldDescriptor?.required);

  const handleName = (v: string, e: any) => {
    setName(v);
    onChange(v, { type, label, required, step });
  };
  const handleType = (v: string, e: any) => {
    setType(v);
    onChange(name, { type: v, label, required, step });
  };
  const handleLabel = (v: string, e: any) => {
    setLabel(v);
    onChange(name, { type, label: v, required, step });
  };
  const handleStep = (v: string, e: any) => {
    setStep(Number(v));
    onChange(name, { type, label, required, step: v });
  };
  const handleRequired = (checked: boolean, e: any) => {
    setRequired(checked);
    onChange(name, { type, label, required: checked, step });
  };
  const removeField = () => {
    onChange(name, { type, label, required, step }, true);
  };

  return (
    <>
      <FormGroup key={`schemaName`} label="Name" isRequired fieldId={`Name `}>
        <span className="flex-display">
          {" "}
          <TextInput
            value={name}
            isRequired
            type="text"
            id={`Name `}
            aria-describedby={`Name `}
            name={`Name `}
            onChange={handleName}
            key={`schemaName `}
          />
          <Button variant="danger" key={`schemaRemove `} onClick={removeField}>
            Remove
          </Button>
        </span>
      </FormGroup>
      <div className="indent">
        <FormGroup
          key={`schemaType `}
          label="Type"
          isRequired
          fieldId={`Type `}
        >
          <TextInput
            value={type}
            isRequired
            type="text"
            id={`Type `}
            aria-describedby={`Type `}
            name={`Type `}
            onChange={handleType}
            key={`schemaType `}
          />
        </FormGroup>
        <FormGroup
          key={`schemaLabel `}
          label="Label"
          isRequired
          fieldId={`Label `}
        >
          <TextInput
            value={label}
            isRequired
            type="text"
            id={`Label `}
            aria-describedby={`Label `}
            name={`Label `}
            onChange={handleLabel}
            key={`schemaLabel `}
          />
        </FormGroup>
        <FormGroup
          key={`schemaRequired `}
          label="Required"
          fieldId={`Required `}
        >
          <Checkbox
            isChecked={required}
            id={`Required `}
            aria-describedby={`Required `}
            name={`Required `}
            onChange={handleRequired}
            key={`schemaRequired `}
            className="checkbox-padding-top"
          />
        </FormGroup>
        {type === "number" && (
          <FormGroup key={`schemaStep `} label="Step" fieldId={`Step `}>
            <TextInput
              value={step}
              type="number"
              step=".01"
              id={`Step `}
              aria-describedby={`Step `}
              name={`Step `}
              onChange={handleStep}
              key={`schemaStep `}
            />
          </FormGroup>
        )}
      </div>
    </>
  );
};
export type BuildSchemaProps = {
  key?: string;
  fieldName?: string;
  fieldDescriptor?: FieldEntry;
  onChange?: any;
};
